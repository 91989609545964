import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://eurohidra.spot4all.com'
    // baseURL: 'http://localhost:8080',
})

const token = localStorage.getItem("token");
api.defaults.headers.Authorization = 'Bearer ' + token;

export const createSession = async (identifier, pin) => {
    return api.post('/external-login', { identifier, pin })
}

export const get_flows = async () => {
    return api.get('/flows_with_relation');
}

export const get_projects = async (id) => {
    return api.get('/projects_by_flow/' + id);
}

export const ponto_entrada = async (dados) => {
    return api.post('/ponto/entrada', dados);
}

export const ponto_saida = async (id, dados) => {
    return api.put('/ponto/saida/' + id, dados);
}

export const last_log = async (user_id) => {
    return api.get('/ponto/last_log/' + user_id);
}

